export const mutations = {
  setActiveStaringFilteredViewId(state, payload) {
    state.activeStaringFilteredViewId = payload;
  },
  setChangeActiveTabView(state, payload) {
    state.changeActiveTabView = payload;
  },
  setGroupByTags(state, data) {
    state.groupByTags[data.status] = data;
    state.groupByTags[data.status].tag = data.tag;
  },
  setIsFetching(state, payload) {
    state.isFetching = {
      ...state.isFetching, ...payload,
    };
  },
  setIsFinishedGetOfProjects(state, isFinished) {
    state.isFinishedGetOfProjects = isFinished;
  },
  setNextToken(state, { status, nextToken }) {
    state[`${status}NextToken`] = nextToken;
  },
  setProjectsList(state, { status, projects }) {
    state[status] = projects;
  },
  setProjectSpinner(state, spinner) {
    state.projectSpinner = spinner;
  },
  /**
   * Only for autocomplete in adding project
   * @param state
   * @param searchText
   */
  setSearchText(state, searchText) {
    state.searchText = searchText;
  },
  setStaringFilteredViews(state, payload) {
    state.staringFilteredViews = payload;
  },
  setAssistantStatus(state, payload) {
    if (!payload) {
      payload = {
        invited: false,
        timestamp: '0',
        isDismissed: false,
      };
    }
    state.assistantStatus = payload;
  },
  setUserPreference(state, payload) {
    state.preferences = {
      ...state.preferences,
      [payload.key]: payload.value,
    };
  },
  setUserPreferences(state, payload) {
    state.preferences = payload;
  },
};
