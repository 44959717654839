import { API } from 'aws-amplify';
import {
  PROJECT_SCHEDULE, USER_INFO,
} from './fragments';
import { convertStringToArray } from '@/utils';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const FIELD_MAPPING_CONNECTION = `
    fieldsMapping {
    scheduleFieldId
    scheduleFieldName
    collectionFieldId
    collectionFieldName
    }
    viewToSortBy
  `;
const VIEW_DATA = `
  {
    columnOrder
    customColumnOrder
    custom_dropdowns
    dropdowns {
      multipleSelect
      name
      values
    }
    filterTag
    id
    name
    role
    sortingField
    rowHeight
    sortingIndex
    type
    visibleColumns
    viewMode
    widget {
      type
    }
    panel
    customDefaultFields
    columnsSize {
      id
      size
      minSize
      unit
    }
  }
`;
const VIEW_DATA_NO_BRACKETS = `
    columnOrder
    customColumnOrder
    custom_dropdowns
    dropdowns {
      multipleSelect
      name
      values
    }
    filterTag
    id
    name
    role
    rowHeight
    sortingField
    sortingIndex
    type
    visibleColumns
    viewMode
    widget {
      type
    }
    panel
    customDefaultFields
    columnsSize {
      id
      size
      minSize
      unit
    }
`;
// columnsSize
export default {
  async getScheduleViews(variables) {
    const res = await new requestBuilder({
      requestName: 'getScheduleViews',
      requestVariables: {
        projectId: 'String!',
        scheduleId: 'String!',
        sharedLink: 'Boolean',
        workspaceId: 'String',
      },
      response: VIEW_DATA_NO_BRACKETS,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();

    // WARNING: For some reason, the filteredTags for views 'filterTag' is stored as a String.
    // - Instead of changing the API + saved data, this conversion was used outside...
    // - I moved it here instead.
    const views = res.data?.response;
    if (views) {
      return views.map(view => {
        return {
          ...view,
          filterTag: !view.filterTag ? [] : convertStringToArray(view.filterTag),
        };
      });
    } else {
      return [];
    }
  },
  saveCustomView(variables) {
    const query = `
      mutation saveCustomView (
        $scheduleId: String!
        $projectId: String!
        $viewId: String!
        $viewName: String!
        $filterTag: String!
        $workspaceId: String
      ) {
        response: saveCustomView (
          scheduleId: $scheduleId
          projectId: $projectId
          viewId: $viewId
          viewName: $viewName
          filterTag: $filterTag
          workspaceId: $workspaceId
        ) ${VIEW_DATA}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  grantPermissionForUsers(variables) {
    return new requestBuilder({
      requestName: 'grantPermissionForUsers',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        tableId: 'String!',
        tableType: 'TableType!',
        viewId: 'String!',
        usernames: '[String]!',
        workspaceId: 'String',
      },
      response: `viewId`,
      variables,
    }).processRequest();
  },
  grantPermissionForRoles(variables) {
    return new requestBuilder({
      requestName: 'grantPermissionForRoles',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        tableId: 'String!',
        tableType: 'TableType!',
        viewId: 'String!',
        roles: '[PermissionType]!',
        workspaceId: 'String',
      },
      response: `viewId`,
      variables,
    }).processRequest();
  },
  deletePermissionForUsers(variables) {
    return new requestBuilder({
      requestName: 'deletePermissionForUsers',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        tableId: 'String!',
        tableType: 'TableType!',
        viewId: 'String!',
        usernames: '[String]!',
        workspaceId: 'String',
      },
      response: `viewId`,
      variables,
    }).processRequest();
  },
  deletePermissionForRoles(variables) {
    return new requestBuilder({
      requestName: 'deletePermissionForRoles',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        projectId: 'String!',
        tableId: 'String!',
        tableType: 'TableType!',
        viewId: 'String!',
        roles: '[PermissionType]!',
        workspaceId: 'String',
      },
      response: `viewId`,
      variables,
    }).processRequest();
  },
  getUsersWithSpecialAccessToView(variables) {
    return new requestBuilder({
      requestName: 'getUsersWithSpecialAccessToView',
      requestVariables: {
        projectId: 'String!',
        tableId: 'String!',
        tableType: 'TableType!',
        viewId: 'String!',
        workspaceId: 'String',
      },
      response: `
        user ${USER_INFO}
      `,
      variables,
    }).processRequest();
  },
  getRolesPermissionsToView(variables) {
    return new requestBuilder({
      requestName: 'getRolesPermissionsToView',
      requestVariables: {
        projectId: 'String!',
        tableId: 'String!',
        tableType: 'TableType!',
        viewId: 'String!',
        workspaceId: 'String',
      },
      response: `role`,
      variables,
    }).processRequest();
  },
  deleteView(variables) {
    return new requestBuilder({
      requestName: 'deleteView',
      requestType: 'mutation',
      requestVariables: {
        scheduleId: 'String!',
        viewId: 'String!',
        projectId: 'String!',
        workspaceId: 'String',
      },
      response: VIEW_DATA_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  updateViewSettings(variables) {
    const query = `
      mutation updateViewSettings (
        $projectId: String!
        $tableId: String!
        $tableType: TableType!
        $viewId: String!
        $visibleColumns: [String]
        $viewMode: ViewModeType
        $workspaceId: String
        $sortingField: String
        $filterTag: String
        $rowHeight: String
      ) {
        response: updateViewSettings (
          projectId: $projectId
          tableId: $tableId
          tableType: $tableType
          viewId: $viewId
          visibleColumns: $visibleColumns
          viewMode: $viewMode
          workspaceId: $workspaceId
          sortingField: $sortingField
          filterTag: $filterTag
          rowHeight: $rowHeight
        ) ${VIEW_DATA}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  updateCustomViewName(variables) {
    const query = `
      mutation updateCustomViewName (
        $scheduleId: String!
        $viewId: String!
        $name: String!
        $projectId: String!
        $workspaceId: String
      ) {
        response: updateCustomViewName (
          scheduleId: $scheduleId
          viewId: $viewId
          name: $name
          projectId: $projectId
          workspaceId: $workspaceId
        ) ${VIEW_DATA}
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  updateViewColumnOrder(variables) {
    return new requestBuilder({
      requestName: 'updateViewColumnOrder',
      requestVariables: {
        workspaceId: 'String!', //Workspace ID where the view is located
        resourceType: 'ViewResourceType!', // Define view resource type, available values: `collection` or `schedule`
        resourceId: 'String!', // Resource id define collectionID or scheduleID
        viewId: 'String!', // View ID where changing order
        projectId: 'String', // Project ID related with view, required when resourceType is `schedule`
        customColumnOrder: '[String]', // List of column in new order
        customDefaultFields: '[String]', // List of fields/columns in new order
      },
      requestType: GRAPHQL_TYPES.MUTATION,
      response: VIEW_DATA_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  updateViewColumnsSize(variables) {
    return new requestBuilder({
      requestName: 'updateViewColumnsSize',
      requestVariables: {
        workspaceId: 'String!', //Workspace ID where the view is located
        resourceType: 'ViewResourceType!', // Define view resource type, available values: `collection` or `schedule`
        resourceId: 'String!', // Resource id define collectionID or scheduleID
        viewId: 'String!', // View ID where changing order
        projectId: 'String', // Project ID related with view, required when resourceType is `schedule`
        columnsSize: '[ColumnSizeInput]!', // List of columns with new sizes (only changed values)
      },
      requestType: GRAPHQL_TYPES.MUTATION,
      response: VIEW_DATA_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  resetView(variables) {
    return new requestBuilder({
      requestName: 'resetView',
      requestVariables: {
        viewId: 'String!',
        projectId: 'String!',
        scheduleId: 'String!',
        workspaceId: 'String!',
      },
      requestType: GRAPHQL_TYPES.MUTATION,
      response: VIEW_DATA_NO_BRACKETS,
      variables,
    }).processRequest();
  },
  restrictColumnEditByRole(variables) {
    return new requestBuilder({
      requestName: 'restrictColumnEditByRole',
      requestVariables: {
        workspaceId: 'String!',
        resourceType: 'ViewResourceType!',
        resourceId: 'String!',
        projectId: 'String!',
        type: 'PermissionType!',
        columnsToRestrict: '[String]!',
      },
      requestType: GRAPHQL_TYPES.MUTATION,
      response: PROJECT_SCHEDULE,
      variables,
    }).processRequest();
  },
  getDefaultFieldsMapping(variables) {
    return new requestBuilder({
      requestName: 'getDefaultFieldsMapping',
      requestVariables: {
        viewToSortBy: 'ViewResourceType!',
      },
      requestType: GRAPHQL_TYPES.QUERY,
      response: FIELD_MAPPING_CONNECTION,
      variables,
    }).processRequest();
  },
};
