import {
  ACTIVE, ARCHIVED,
} from '@/constants';
export const state = {
  active: [],
  activeNextToken: '',
  activeStaringFilteredViewId: '',
  archived: [],
  archivedNextToken: '',
  changeActiveTabView: null,
  filter: null,
  groupByTags: {
    active: {
      groupByTagsTrigger: false,
      status: ACTIVE,
      tag: '',
    },
    archived: {
      groupByTagsTrigger: false,
      status: ARCHIVED,
      tag: '',
    },
  },
  isFetching: {
    projectsList: false,
    assistantStatus: false,
    preferences: false,
  },
  isFinishedGetOfProjects: false,
  projectSpinner: 0,
  searchText: '',
  staringFilteredViews: [],

  assistantStatus: {
    id: '',
    invited: false,
    isDismissed: false,
    timestamp: '0',
  },
  preferences: {
  },
};
