<template>
  <InteractiveTooltip
    content-class="btn-tooltip"
    top
    :nudge-top="$vuetify.breakpoint.lgAndUp ? 80 : -105">
    <template v-slot:activator="{ on }">
      <div
        v-if="AIROPS_WIDGET_ID && showAIChat"
        :data-airops-widget="AIROPS_WIDGET_ID"
        v-on="canUseChat ? '' : on" />
    </template>
    <span>
      To access Sourcery AI, upgrade your workspace
      <router-link :to="getFullLinkForWorkspaces('workspace-payment')">here</router-link>
    </span>
  </InteractiveTooltip>
</template>
<script>
import { mapGetters } from 'vuex';
import InteractiveTooltip from '@/components/App/AppInteractiveTooltip';
import { getFullLinkForWorkspaces } from '@/utils';
import {
  AIROPS_WIDGET_URL,
  AIROPS_WIDGET_ID,
} from '@/constants/airops';

export default {
  name: 'AppAIChat',
  components: {
    InteractiveTooltip,
  },
  data() {
    return {
      widgetStyleUpdateFn: null,
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['showAIChat']),
    ...mapGetters('UserRoles', ['canUseAIChat']),
    ...mapGetters('FeatureFlags', ['corePackageUpdates']),
    canUseChat() {
      return this.canUseAIChat?.allowed;
    },
  },
  watch: {
    canUseChat: {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.enableAIChatButton();
        } else if (!newValue && oldValue) {
          this.disableAIChatButton();
        }
      },
    },
  },
  created() {
    this.AIROPS_WIDGET_ID = AIROPS_WIDGET_ID;
  },
  mounted() {
    this.initChatWidget();
  },
  destroyed() {
    this.destroyChatWidget();
  },
  methods: {
    getFullLinkForWorkspaces,

    initChatWidget() {
      if (this.widgetStyleUpdateFn) clearTimeout(this.widgetStyleUpdateFn);
      if (window.AirOps) {
        window.AirOps.initialize();
        this.initialWidgetLoadPoll();
      } else {
        this.loadWidgetScript();
      }
    },
    destroyChatWidget() {
      if (this.widgetStyleUpdateFn) clearTimeout(this.widgetStyleUpdateFn);
      window.AirOps?.deactivate();
    },
    loadWidgetScript() {
      if (document.querySelector(`script[src="${AIROPS_WIDGET_URL}"]`)?.length) {
        console.warn('Tried loading widget while script exists?');
        window.AirOps?.initialize();
        return;
      }
      const script = document.createElement('script');
      script.src = AIROPS_WIDGET_URL;
      script.async = true;
      script.onload = () => {
        window.AirOps.initialize();
        this.initialWidgetLoadPoll();
      };
      document.head.appendChild(script);
    },
    getAirOpsElem() {
      return document.querySelectorAll(`[data-airops-widget="${AIROPS_WIDGET_ID}"]`)?.[0];
    },
    initialWidgetLoadPoll() {
      if (this.widgetStyleUpdateFn) clearTimeout(this.widgetStyleUpdateFn);

      const pollStyleUpdate = () => {
        if (!this.updateStyles()) {
          this.widgetStyleUpdateFn = setTimeout(pollStyleUpdate, 500);
        } else {
          if (this.canUseChat) {
            this.enableAIChatButton();
          } else {
            this.disableAIChatButton();
          }
        }
      };
      pollStyleUpdate();
    },
    disableAIChatButton() {
      const disableButton = () => {
        if (window.AirOps?.status !== 'initialized') return false;

        const host = this.getAirOpsElem();
        if (!host || !host.shadowRoot) return false;

        const shadowRoot = host.shadowRoot;
        const buttons = shadowRoot.querySelectorAll('button.ao-absolute[type="button"]');
        if (buttons.length === 0) return false;

        for (const btn of buttons) {
          btn.disabled = true;
          btn.style.cursor = 'not-allowed';
          btn.style.opacity = '0.25';
        }
        return true;
      };

      if (this.widgetStyleUpdateFn) clearTimeout(this.widgetStyleUpdateFn);
      const disablePoll = () => {
        if (!disableButton()) this.widgetStyleUpdateFn = setTimeout(disablePoll, 500);
      };
      disablePoll();
    },
    enableAIChatButton() {
      const enableButtons = () => {
        if (window.AirOps?.status !== 'initialized') return false;

        const host = this.getAirOpsElem();
        if (!host || !host.shadowRoot) return false;

        const shadowRoot = host.shadowRoot;
        const buttons = shadowRoot.querySelectorAll('button.ao-absolute[type="button"]');
        if (buttons.length === 0) return false;

        for (const btn of buttons) {
          btn.d = false;
          btn.style.cursor = 'pointer';
          btn.style.opacity = '1';
        }
        return true;
      };

      if (this.widgetStyleUpdateFn) clearTimeout(this.widgetStyleUpdateFn);
      const enablePoll = () => {
        if (!enableButtons()) this.widgetStyleUpdateFn = setTimeout(enableButtons(), 500);
      };
      enablePoll();
    },
    updateStyles() {
      if (window.AirOps?.status !== 'initialized') return false;

      const host = this.getAirOpsElem();
      if (!host || !host.shadowRoot) return false;

      const shadowDom = host.shadowRoot;
      const shadowStyle = document.createElement('style');

      shadowStyle.textContent = `
        .ao-h-\\[740px\\] {
          height: 580px;
        }
        .ao-w-\\[400px\\] {
          width: 300px;
          @media (min-width: 992px) {
            width: 400px;
          }
        }
        .ao-bg-primary, .ao-bg-user-message {
          background: var(--ao-primary);
        }
        .ao-bottom-12 {
          bottom: calc(50% - 50px);
          right: 12px;
          @media (min-width: 992px) {
            bottom: 20px;
            right: 20px;
          }
        }
        .ao-h-12.ao-w-12 {
          height: 45px;
          width: 45px;

          @media (min-width: 992px) {
            height: 65px;
            width: 65px;
          }

          svg {
            height: 20px;
            width: 20px;

            @media (min-width: 992px) {
              height: 30px;
              width: 30px;
            }
          }
        }
        .ao-absolute.ao-bottom-16.ao-right-0 {
          position: fixed;
          bottom: 6.5rem;
          right: 4rem;

          @media (min-width: 992px) {
            position: absolute;
            bottom: 4.5rem;
            right: 0;
          }
        }
      `;
      shadowDom.appendChild(shadowStyle);

      return true;
    },
  },
};
</script>

<style lang="scss">
div[data-airops-widget] {
  --ao-primary: var(--v-black-base);
  --ao-user-message: var(--v-black-base);
  z-index: 99;
}
</style>