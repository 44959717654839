// types
const TYPE_CELL_BADGE = 'cell-badge';
const TYPE_COLLECTION_SHARE = 'collection-share';
const TYPE_CREATE = 'create';
const TYPE_COMPARE = 'compare';
const TYPE_DUPLICATE = 'duplicate';
const TYPE_EDIT = 'edit';
const TYPE_FOLLOW = 'follow';
const TYPE_LIBRARY_CUSTOM = 'custom';
const TYPE_LIBRARY_DEFAULT = 'default';
const TYPE_READONLY = 'readOnly';
const TYPE_ROW_BADGE = 'row-badge';
const TYPE_SAVE_AS_NEW = 'save as new';
const TYPE_SPEC_SHEET = 'specs';
const TYPE_UPDATE_REFERENCED_PRODUCT = 'update referenced product';
const TYPE_WORKSPACE_FREE = 'free';
const TYPE_WORKSPACE_PREMIUM = 'premium';
const TYPE_WORKSPACE_STARTER = 'starter';
const TYPE_WORKSPACE_SELF_SERVE = 'self_serve';
const TYPE_WORKSPACE_MANAGED = 'managed';
const TYPE_WORKSPACE_EXPIRED = 'premiumExpired';
const PAID_WORKSPACE_TYPES = [
  TYPE_WORKSPACE_PREMIUM,
  TYPE_WORKSPACE_STARTER,
  TYPE_WORKSPACE_SELF_SERVE,
  TYPE_WORKSPACE_MANAGED,
];
// For Upgrade/Downgrade checkin
const ORDERED_WORKSPACE_TYPES = [
  [TYPE_WORKSPACE_FREE],
  [TYPE_WORKSPACE_STARTER],
  [TYPE_WORKSPACE_PREMIUM, TYPE_WORKSPACE_SELF_SERVE],
  [TYPE_WORKSPACE_MANAGED],
];
const TYPE_WORKSPACE_SHARE = 'workspace-share';
const COLLECTIONS_DEFAULT_NAME = 'My Products';
const IS_STORE_ROOT = {
  root: true,
};
// project statuses
const ACTIVE = 'active';
const ARCHIVED = 'archived';
// ids
const PERSONAL_WORKSPACE_ID = 'personal';
const COMMUNITY_ID = 'community';
// collection views ids
const COMMUNITY_VIEW_ID = 'community';
const DEFAULT_VIEW_ID = 'default';
// time for debouncing (in milliseconds)
const DEBOUNCE_TIME_FOR_CLICK = 200;
const DEBOUNCE_TIME_FOR_SEARCHING = 300;
const DEBOUNCE_TIME_TO_RESIZE_EVENTS = 300;
//collection's group
const GROUP_SHARED = 'shared';
const GROUP_FOLLOWED = 'followed';
//name router
const ROUTE_COLLECTIONS = 'collections';
const ROUTE_COLLECTION_LIBRARY = 'collection-library';
const ROUTE_COMMUNITY = 'community';
const ROUTE_COMMUNITY_COLLECTION = 'community-collection';
const ROUTE_COMMUNITY_COLLECTIONS = 'community-collections';
const ROUTE_PROJECTS = 'projects';
const ROUTE_VERIFIED_PAGE = 'verified-community';
const ROUTE_WORKSPACE_PAGE = 'workspace-page';
const ROUTE_WORKSPACE_PAGE_EDIT = 'workspace-page-edit';
const ROUTES_FOR_PRODUCT_MODAL_V1 = ['collection-product-link', 'community-collection-product-link'];
const ROUTES_FOR_PRODUCT_MODAL_V2 = ['collection-product-link', 'community-collection-product-link', 'shared-collection-product-link', 'embed-collection-product-link'];
// collection's types
const UNLISTED_PUBLISH = 'unlistedPublish';
const PUBLISH = 'publish';
const PRIVATE = 'private';
const SHARED_COLLECTION_PAGE_NAME = 'shared-collection-product-link';
const ALLOW_TYPES_TO_SUGGEST = [
  UNLISTED_PUBLISH,
  PUBLISH,
];
// limits
const COLLECTION_PRODUCTS_LIMIT = 100;
const PROJECTS_LIST_LIMIT = 100;
const FREE_GUEST_LIMIT = 10; // Note actual limit is on backend data nd we fetch that -- this is a fallback amount.

const CONFIRM_TO_PROCEED_IF_FREE_USERS = 'The role you are assigning will allow users to edit project data, are you sure?';
// statuses of schedule comparing
const ADDED = 'added';
const DELETED = 'deleted';
const MODIFIED = 'modified';
export {
  ACTIVE,
  ADDED,
  ALLOW_TYPES_TO_SUGGEST,
  ARCHIVED,
  COLLECTION_PRODUCTS_LIMIT,
  COLLECTIONS_DEFAULT_NAME,
  COMMUNITY_ID,
  COMMUNITY_VIEW_ID,
  CONFIRM_TO_PROCEED_IF_FREE_USERS,
  DEFAULT_VIEW_ID,
  DEBOUNCE_TIME_FOR_CLICK,
  DEBOUNCE_TIME_FOR_SEARCHING,
  DEBOUNCE_TIME_TO_RESIZE_EVENTS,
  DELETED,
  FREE_GUEST_LIMIT,
  GROUP_FOLLOWED,
  GROUP_SHARED,
  IS_STORE_ROOT,
  MODIFIED,
  PERSONAL_WORKSPACE_ID,
  PRIVATE,
  PROJECTS_LIST_LIMIT,
  PUBLISH,
  ROUTE_COLLECTIONS,
  ROUTE_COLLECTION_LIBRARY,
  ROUTE_COMMUNITY,
  ROUTE_COMMUNITY_COLLECTION,
  ROUTE_COMMUNITY_COLLECTIONS,
  ROUTE_PROJECTS,
  ROUTE_VERIFIED_PAGE,
  ROUTE_WORKSPACE_PAGE,
  ROUTE_WORKSPACE_PAGE_EDIT,
  ROUTES_FOR_PRODUCT_MODAL_V1,
  ROUTES_FOR_PRODUCT_MODAL_V2,
  TYPE_CELL_BADGE,
  TYPE_COLLECTION_SHARE,
  TYPE_COMPARE,
  TYPE_CREATE,
  TYPE_DUPLICATE,
  TYPE_EDIT,
  TYPE_FOLLOW,
  TYPE_LIBRARY_CUSTOM,
  TYPE_LIBRARY_DEFAULT,
  TYPE_READONLY,
  TYPE_ROW_BADGE,
  TYPE_SAVE_AS_NEW,
  TYPE_SPEC_SHEET,
  TYPE_UPDATE_REFERENCED_PRODUCT,
  TYPE_WORKSPACE_FREE,
  TYPE_WORKSPACE_PREMIUM,
  TYPE_WORKSPACE_SHARE,
  TYPE_WORKSPACE_STARTER,
  TYPE_WORKSPACE_SELF_SERVE,
  TYPE_WORKSPACE_MANAGED,
  TYPE_WORKSPACE_EXPIRED,
  PAID_WORKSPACE_TYPES,
  ORDERED_WORKSPACE_TYPES,
  UNLISTED_PUBLISH,
  SHARED_COLLECTION_PAGE_NAME,
};
