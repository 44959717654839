import Vue from 'vue';
import { Auth } from 'aws-amplify';
import VueRouter from 'vue-router';
import store from '../store/index';
import {
  beforeWorkspaceAdminpanelEnter,
  beforeGetFileEnter,
  beforeRedirectNewUser,
  checkTutorialDisplay,
  checkUnsavedDataBeforeLeave,
  beforeVerifiedCommunityEnter,
  isUserProfileComplete,
} from '@/utils';
import { QUERY_ALIAS_TO_PROPS } from '@/constants/embedLinkParams';
import { beforeQuickLinkEnterV2 } from '@/utils/routerMiddlewares';
import {
  ROUTE_WORKSPACE_PAGE, ROUTE_WORKSPACE_PAGE_EDIT,
  TYPE_WORKSPACE_FREE, TYPE_WORKSPACE_EXPIRED,
} from '@/constants';
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    redirect: to => ({
      name: 'workspaces',
      query: to.query,
      params: {
        checkProfileComplete: true,
      },
    }),
  },
  {
    path: '/collection/:id/shared',
    name: 'shared-collection',
    params: true,
    component: () => lazyLoadView(import('@/views/SharedCollectionDetails'), false),
    children: [
      {
        path: 'product/:productId',
        name: 'shared-collection-product-link',
        component: () => lazyLoadView(import('@/views/MyCollectionProduct'), false),
        meta: {
          requiresAuth: false,
          allowGetRows: true,
        },
        props: {
          autoHideUnpopulatedFields: true,
        },
      },
    ],
    meta: {
      requiresAuth: false,
      canVisitAnonymous: true,
      allowGetRows: true,
    },
  },
  {
    path: '/workspace/:workspaceId/page/:pageId/shared',
    name: 'shared-workspace',
    params: true,
    component: () => lazyLoadView(import('@/views/SharedWorkspaceDetails/index.vue')),
    meta: {
      requiresAuth: false,
      canVisitAnonymous: true,
      allowGetRows: true,
    },
    props: {
      isWorkspacePage: true,
      isSharedPage: true,
    },
  },
  {
    path: '/collection/:id/embed',
    name: 'embed-collection',
    params: true,
    component: () => lazyLoadView(import('@/views/EmbedCollectionDetails')),
    props: route => {
      return Object.entries(route.query).reduce((acc, q) => {
        const [key, value] = [q[0], q[1]];
        if (value) {
          acc[QUERY_ALIAS_TO_PROPS[key]] = true;
        }
        return acc;
      }, {
      });
    },
    meta: {
      requiresAuth: false,
      canVisitAnonymous: true,
      allowGetRows: true,
    },
    children: [
      {
        path: 'product/:productId',
        name: 'embed-collection-product-link',
        component: () => lazyLoadView(import('@/views/MyCollectionProduct')),
        meta: {
          requiresAuth: false,
          allowGetRows: true,
        },
        props: {
          autoHideUnpopulatedFields: true,
        },
      },
    ],
  },
  {
    path: '*',
    redirect: to => ({
      name: 'workspaces',
      query: to.query,
    }),
  },
  {
    path: '/workspace/:wId',
    name: 'workspaces',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/Workspaces'), false),
    meta: {
      requiresAuth: true,
    },
    beforeEnter(to, from, next) {
      checkTutorialDisplay();
      beforeRedirectNewUser(to, from, next);
    },
    children: [
      {
        path: 'projects',
        name: 'projects',
        props: true,
        params: true,
        component: () => lazyLoadView(import('@/views/Projects')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'workspace-payment',
        name: 'workspace-payment',
        props: true,
        params: true,
        component: () => lazyLoadView(import('@/views/Payment/WrapperWorkspacePayment'), false),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'workspace-adminpanel/:field',
        name: 'workspace-adminpanel-members',
        params: true,
        component: () => lazyLoadView(import('@/views/WorkspaceAdminpanel')),
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          beforeWorkspaceAdminpanelEnter(to, from, next);
        },
      },
      {
        // path: '/project/:id/:mode',
        path: 'project/:id',
        name: 'schedule-library',
        params: true,
        component: () => lazyLoadView(import('@/views/ProjectDetails')),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'collection/:id/:libraryId?',
        name: 'collection-library',
        params: true,
        component: () => lazyLoadView(import('@/views/MyCollectionDetails')),
        children: [
          {
            path: 'product/:productId',
            name: 'collection-product-link',
            component: () => lazyLoadView(import('@/views/MyCollectionProduct')),
            meta: {
              requiresAuth: true,
              allowGetRows: true,
            },
            beforeEnter: async (to, from, next) => {
              if (to.params?.ignoreMiddleware) {
                next();
                return;
              }
              beforeQuickLinkEnterV2(to, from, next, store);
              return;
            },
          },
        ],
        meta: {
          requiresAuth: true,
          type: 'private',
          allowGetRows: true,
        },
      },
      {
        path: 'community-collection/:id',
        name: 'community-collection',
        params: true,
        component: () => lazyLoadView(import('@/views/CommunityCollectionDetails')),
        meta: {
          requiresAuth: true,
          type: 'community',
          allowGetRows: true,
        },
        children: [
          {
            path: 'product/:productId',
            name: 'community-collection-product-link',
            component: () => lazyLoadView(import('@/views/MyCollectionProduct')),
            meta: {
              requiresAuth: true,
              allowGetRows: true,
            },
          },
        ],
      },
      {
        path: 'shared-project/:id',
        name: 'project-library',
        params: true,
        component: () => lazyLoadView(import('@/views/ProjectDetails/WrapperProjectSharedLink')),
        meta: {
          requiresAuth: false,
          canVisitAnonymous: true,
        },
      },
      {
        path: 'collections/:id',
        name: 'collections',
        params: true,
        component: () => lazyLoadView(import('@/views/MyCollections'), false),
        meta: {
          requiresAuth: true,
          type: 'private',
        },
      },
      {
        path: 'community-collections/:id',
        name: 'community-collections',
        params: true,
        component: () => lazyLoadView(import('@/views/CommunityCollections')),
        meta: {
          requiresAuth: true,
          type: 'community',
        },
      },
      {
        path: 'verified-community/:id',
        name: 'verified-community',
        params: true,
        component: () => lazyLoadView(import('@/views/VerifiedCommunity')),
        beforeEnter: async (to, from, next) => {
          beforeVerifiedCommunityEnter(to, from, next);
        },
        meta: {
          requiresAuth: true,
          type: 'community',
        },
      },
      {
        path: 'my-pages',
        name: 'my-page',
        params: true,
        component: () => lazyLoadView(import('@/views/MyPage'), false),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'community-collections/:pageId/:id/',
        name: ROUTE_WORKSPACE_PAGE,
        params: true,
        component: () => lazyLoadView(import('@/views/CommunityCollections')),
        meta: {
          requiresAuth: true,
          type: 'community',
          isWorkspacePage: true,
        },
        props: {
          isWorkspacePage: true,
          canUseQuickViewLink: true,
        },
      },
      {
        path: 'workspace-page/:pageId',
        name: ROUTE_WORKSPACE_PAGE_EDIT,
        params: true,
        component: () => lazyLoadView(import('@/views/CommunityCollections')),
        meta: {
          requiresAuth: true,
          type: 'community',
          isWorkspacePage: true,
          isWorkspaceEditPage: true,
        },
        props: {
          isWorkspacePage: true,
          isWorkspaceEditPage: true,
          canUseQuickViewLink: true,
        },
      },
      {
        path: 'libraries',
        name: 'libraries',
        params: true,
        component: () => lazyLoadView(import('@/views/Libraries')),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/account',
    redirect: '/account/personal-info',
  },
  {
    path: '/account/:field',
    name: 'account',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/Account')),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/Login')),
    meta: {
      requiresAuth: false,
      showRouteLoader: true,
    },
  },
  {
    path: '/bostonlightslogin',
    name: 'event-login',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/EventLogin')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/bostonlightssignup',
    name: 'event-sign-up',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/EventSignUp')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/event-page',
    name: 'event-page',
    redirect: () => ({
      // TODO: save pageId in store
      // make sure to change the id in utils/index
      name: 'workspace-page',
      params: {
        pageId: '9ebd3105-66fd-48ab-a19b-ba38ad20d156',
        id: 'community',
        wId: store.state.Workspace.activeWorkspaceId,
      },
    }),
  },
  {
    path: '/white-label',
    name: 'white-label',
    component: () => lazyLoadView(import('@/views/Agent/Login')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/white-label-forgot',
    name: 'white-label-forgot',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/Agent/ResetPassword')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/white-label-reset',
    name: 'white-label-reset',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/Agent/ConfirmResetPassword')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/complete-new-password',
    name: 'complete-new-password',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/CompleteNewPassword')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/SignUp')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/confirm-sign-up',
    name: 'confirm-sign-up',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/ConfirmSignUp')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/ResetPassword')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/confirm-reset-password',
    name: 'confirm-reset-password',
    props: true,
    params: true,
    component: () => lazyLoadView(import('@/views/ConfirmResetPassword')),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/payment',
    name: 'payment',
    props: true,
    params: true,
    beforeEnter: (to, from, next) => {
      next({
        name: 'workspace-payment',
        params: {
          wId: store.state.Workspace.activeWorkspaceId,
        },
      });
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/getFile',
    name: 'get-file',
    props: true,
    params: true,
    component: () => import('@/views/GetFile'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      beforeGetFileEnter(to, from, next);
    },
  },
];
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      top: 0,
      behavior: 'smooth',
    };
  },
});

/**
 * Before Each for every route.
 * - Params that are used in 'to':
  *   > 'checkProfileComplete'
 *      = will do check if we have to redirect to 'account' (if
 *        any userData is incomplete).
 *   > 'skipPrepare'
 *      = skips getting userData/prepare workspaces
 *      = useful if we know it's prepared, like a redirect from this method
 *        when checking with 'checkProfileComplete' on.
 *
 * For non-signup/login:
 * - Checks Auth + Payment
 * - Gets & Sets UserData
 * - Gets & Sets Workspace data (prepare workspaces).
 */
router.beforeEach((to, from, next) => {
  const { state, commit, dispatch } = store;
  // try setting 'showRouteLoader' only on routes you are sure will take long to load.
  // loader page is bad UX when overused:
  // https://app.asana.com/0/1205146388025688/1205959243503819
  if (store.getters['FeatureFlags/lessRefreshing']) {
    if (to.matched.some(record => record.meta.showRouteLoader === true)) {
      commit('setIsLoadingRoute', true);
    }
  }
  if (to.params.showRouteLoader === true) {
    commit('setIsLoadingRoute', true);
  }

  const accessToken = to.query?.accessToken;
  accessToken && commit('setAccessToken', accessToken);
  const { notSavedDataFlag } = state;
  if (notSavedDataFlag) {
    checkUnsavedDataBeforeLeave(next);
  } else {
    if (to.matched.every(record => record.meta.requiresAuth && !record.meta?.canVisitAnonymous)) {
      // Routes needing Auth/Signin
      (async () => {
        try {
          if (!state.FeatureFlags.listFeatureFlags.length) {
            await dispatch('FeatureFlags/getListFeatureFlags');
          }
          const response = await Auth.currentAuthenticatedUser();
          if (response.signInUserSession) {
            commit('setUserInfo', response.signInUserSession.idToken.payload);

            if (!to.params?.skipPrepare) {
              const promises = [];
              promises.push(dispatch('Workspace/prepareWorkspaces', {
                params: to.params,
                matched: to.matched,
                query: to.query,
                checkBackOff: to.params?.newRegistrationFlow,
              }));
              const userInfo = store.state.userInfo;
              if (!state.UserProfile.userData && userInfo) {
                promises.push(dispatch('UserProfile/getUserProfile'));
              }
              await Promise.allSettled(promises);
            }

            // Redirect to payment page if workspace is "free" or "expired"
            if (to.name !== 'workspace-payment') {
              const wsType = store.getters['Workspace/getActiveWorkspaceType'];
              if (wsType === TYPE_WORKSPACE_FREE || wsType === TYPE_WORKSPACE_EXPIRED) {
                next({
                  name: 'workspace-payment',
                  params: {
                    wId: store.state.Workspace.activeWorkspaceId,
                    skipPrepare: true,
                    showRouteLoader: true,
                  },
                });
                return;
              }
            }

            // Redirect to account page if user profile is not complete
            if (to.params?.checkProfileComplete) {
              if (!isUserProfileComplete(state.UserProfile.userData)) {
                next({
                  path: '/account/personal-info',
                  params: {
                    skipPrepare: true,
                    showRouteLoader: true,
                  },
                });
                return;
              }
            }

            const userInfo = store.state.userInfo;
            if (!state.subscribePayment
              && !state.subscribePaymentSuccess
              && userInfo
              && !userInfo['cognito:groups']) {
              await dispatch('subscribePaymentEventSuccess');
              await dispatch('subscribePaymentEvent');
            } else if (state.subscribePayment
              && state.subscribePaymentSuccess
              && userInfo
              && state.userInfo['cognito:groups']
            ) {
              state.subscribePayment.unsubscribe();
              state.subscribePaymentSuccess.unsubscribe();
            }
            if (userInfo === null) {
              next({
                name: 'login',
              });
            } else {
              next(); // go to wherever I'm going
            }
          }
        } catch (err) {
          if (to?.redirectedFrom !== '/') {
            const {
              fullPath = '', redirectedFrom = '', path = '', name = '', params = {
              },
            } = to || {
            };
            const router = {
              fullPath,
              redirectedFrom,
              path,
              name,
              params,
            };
            sessionStorage.setItem('previousRouter', JSON.stringify(router));
          } // to remember start router
          if (to.hash && to.hash.includes('error_description=PreSignUp+failed+with+error')) {
            const decodeHash = decodeURIComponent(to.hash);
            // eslint-disable-next-line
            const regForEmail = decodeHash.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi);
            try {
              const splittedHash = regForEmail[0].split('email=');
              next({
                name: 'login', params: {
                  merged: true, email: splittedHash[1],
                },
              });
            } catch {
              next({
                name: 'login', params: {
                  merged: true,
                },
              });
            }
          } else {
            if (!state.FeatureFlags.listFeatureFlags.length) {
              await dispatch('FeatureFlags/getListFeatureFlags');
            }
            const { query } = to;
            if (to.name === 'payment' || (query && query.hasOwnProperty('forcepay'))) {
              const forcepay = query.forcepay || false;
              next({
                name: 'login', query: {
                  forcepay,
                },
              });
            } else {
              if (['follow', 'followV2'].includes(to.query?.action)) {
                next({
                  name: 'sign-up',
                });
                return;
              }
              next({
                name: 'login',
              });
            }
          }
          console.log('err', err);
        }
      })();
    } else {
      // Routes not needing Auth/Signin
      (async () => {
        if (to.name !== 'login') {
          // we do not need workspaces on login page
          await dispatch('Workspace/prepareWorkspaces', {
            params: to.params,
            matched: to.matched,
            query: to.query,
            isAnonymous: true,
          });
          if (!state.FeatureFlags.listFeatureFlags.length) {
            await dispatch('FeatureFlags/getListFeatureFlags');
          }
        }
        next(); // go to wherever I'm going
      })();
    }
  }
});
router.afterEach((to) => {
  const { commit, dispatch } = store;
  commit('setIsLoadingRoute', false);
  dispatch('computeActiveHeaderLink', to);
});
function lazyLoadView(AsyncView, loadingSpinner = true) {
  if (!store.getters['FeatureFlags/lessRefreshing']) {
    loadingSpinner = true;
  }
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: loadingSpinner ? import('@/components/App/AppLoadingSpinner') : undefined,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: import ('@/components/App/AppErrorComponent'),
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });
  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}
export default router;
