import router from '@/router';

import API from '@/services/graphql';
import LibrariesApi from '@/services/graphql/libraries';
import ProjectColumn from '@/services/graphql/projectColumn';
import ProjectPermissionsApi from '@/services/graphql/projectPermissions';
import ProjectTagsApi from '@/services/graphql/projectTags';
import ScheduleCells from '@/services/graphql/scheduleCells';
import Subscriptions from '@/services/graphql/subscriptions';
import UserProfileApi from '@/services/graphql/userProfile';

import {
  IS_STORE_ROOT, TYPE_READONLY,
} from '@/constants';
import { Storage } from 'aws-amplify';

export const actions = {
  async createCustomColumn({ commit, dispatch, state }, { columnName, columnType, projectId, sharedLink = false }) {
    const scheduleId = state.scheduleId;
    const {
      data = {
      },
    } = await ProjectColumn.createProjectColumn({
      columnName, columnType, projectId, scheduleId,
    });
    const {
      response = {
      },
    } = data ?? {
    };

    const { id, schema = [], default_fields = [], read_only_fields = [], custom_fields = [] } = response;
    commit('setScheduleId', id);
    commit('setSchema', schema);
    commit('setDefaultFields', default_fields);
    commit('setCustomFields', custom_fields);
    commit('setReadOnlyFields', read_only_fields);

    await dispatch('ScheduleViews/getViews', {
      projectId,
      sharedLink,
    }, IS_STORE_ROOT);
  },
  async deleteProjectColumnAction({ commit, dispatch, state }, { customField, projectId, sharedLink = false }) {
    try {
      const scheduleId = state.scheduleId;
      const {
        data = {
        },
      } = await ProjectColumn.deleteProjectCustomColumn({
        columnName: customField,
        projectId,
        scheduleId,
      });
      const {
        response = {
        },
      } = data ?? {
      };

      const { schema = [], custom_fields = [] } = response;
      commit('setSchema', schema);
      commit('setCustomFields', custom_fields);

      await dispatch('ScheduleViews/getViews', {
        projectId,
        sharedLink,
      }, IS_STORE_ROOT);
    } catch (err) {
      console.log('deleteProjectColumnAction err', err);
    }
  },
  async getSchedule({ commit, dispatch, rootState }, { sharedLink, initData = null, projectId }) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      if (!projectId) return false;
      const {
        data = {
        },
      } = initData || await API.getSchedule({
        projectId,
        sharedLink,
        workspaceId,
      });
      const {
        response = {
        },
      } = data ?? {
      };
      const { id, schema = [], default_fields = [], read_only_fields = [], custom_fields = [] } = response;
      commit('setScheduleId', id);
      commit('setSchema', schema);
      commit('setDefaultFields', default_fields);
      commit('setCustomFields', custom_fields);
      commit('setReadOnlyFields', read_only_fields);
      await dispatch('ScheduleViews/getViews', {
        projectId,
        sharedLink,
      }, IS_STORE_ROOT);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async setCopiedContent({ commit }, { checkClipboardAbility = true } = {
  }) {
    let data = null;
    try {
      await navigator.clipboard.readText().then(text => data = text);
      commit('setCopiedContent', data);
    } catch (err) {
      if (checkClipboardAbility) {
        const text = `Your browser has blocked ability to work with clipboard,
          as the user has dismissed the permission prompt several
          times. This can be reset in Page Info which can be accessed
          by clicking the lock icon next to the URL.`;
        commit('openSnackBar', {
          text,
          timeout: 5000,
        }, IS_STORE_ROOT);
      }
    }
  },
  async getLibrary({ commit, rootState }) {
    try {
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { id: projectId } = router.currentRoute.params;
      const { data } = await LibrariesApi.getLibrary({
        projectId,
        workspaceId,
      });
      commit('setLibraryId', data.response.id);
    } catch (err) {
      console.log('err getLibrary', err);
    }
  },
  async getProjectPermissions({ commit, rootState, rootGetters }) {
    commit('setProjectPermissions', []);
    try {
      const { projectId: id } = rootState;
      const { userId } = rootGetters;
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const projectId = router.currentRoute.params.id || id;
      const { data } = await ProjectPermissionsApi.getProjectPermissions({
        projectId,
        workspaceId,
      });
      const permissions = data.response;
      const user = permissions.find(user => user.userInfo?.id === userId);
      commit('Comments/setProjectUserHideResolvedToggle', user?.hideResolvedComments, IS_STORE_ROOT);
      commit('setProjectPermissions', permissions);
      if (user) {
        commit('changeRole', user.type, IS_STORE_ROOT);
      }
    } catch (err) {
      console.log(err);
    }
  },
  async getProjectLogo({ dispatch, rootGetters }) {
    const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
    if (isPersonalWorkspace) {
      dispatch('getCreatorLogo');
    } else {
      dispatch('Workspace/getActiveWorkspaceLogo', null, IS_STORE_ROOT);
    }
  },
  async getCreatorLogo({ commit, rootGetters }) {
    const username = rootGetters['ProjectDetails/projectDetailsCreatorId'];
    if (username) {
      try {
        const { data } = await UserProfileApi.getUserProfile({
          username,
        });
        const { logo: key } = data.response;
        if (key) {
          const url = await Storage.get(key,
            {
              level: 'public',
            });
          commit('setCreatorLogo', url);
          commit('setCreatorLogoKey', key);
        }
      } catch (err) {
        console.log('getCreatorLogo err', err);
      }
    }
  },
  async getAvailReferenceFields({ commit }) {
    try {
      const { data } = await ScheduleCells.getAvailReferenceFields();
      commit('setAvailReferenceFields', data.response);
    } catch (err) {
      console.log('getAvailReferenceFields err', err);
    }
  },
  async getAllProjectTags({ commit, dispatch }) {
    try {
      const { data } = await ProjectTagsApi.getProjectTags();
      const allTags = data.response.filter(item => item !== 'untagged');
      commit('setProjectTags', allTags);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  showCommentsModalTrigger({ commit }, { rowId = null, privateMode = null }) {
    // reset modal view
    this.commit('Collections/changeProductModalVariant', TYPE_READONLY);
    commit('setCommentsModal');
    commit('setModalRowId', rowId);
    commit('setModalMode', privateMode);
  },
  clearCommentsModalTrigger({ commit }) {
    commit('clearCommentsModal');
    commit('setModalRowId', null);
    commit('setModalMode', null);
  },
  async subscribeUnlockProject({ commit, dispatch }, { requests = null }) {
    const { id } = router.currentRoute.params;
    try {
      const subscription = await Subscriptions.subscribeUnlockProject({
        projectId: id,
      }).subscribe({
        next: async () => {
          if (requests?.getRowsForVersion) {
            const { versionId, sharedLink } = requests.getRowsForVersion;
            await dispatch('ScheduleRows/getRows', {
              versionId,
              sharedLink,
            }, IS_STORE_ROOT);
          }
          commit('openSnackBar', {
            title: 'New version has been successfully created',
          }, IS_STORE_ROOT);
        },
      });
      commit('addTableSubscriptions', subscription, IS_STORE_ROOT);
    } catch (err) {
      console.log('subscribeUnlockProject err', err);
    }
  },
  subscribeRowRelated({ dispatch, rootState }) {
    const { id: projectId } = router.currentRoute.params;
    const { scheduleId: tableId } = rootState['ProjectDetailsTableSchedule'];
    const tableType = 'schedule';
    dispatch('ScheduleRows/subscribeDeleteRow', {
      tableId,
      tableType,
    }, IS_STORE_ROOT);
    dispatch('ScheduleRows/subscribePutRow', {
      tableId,
      tableType,
    }, IS_STORE_ROOT);
    dispatch('ProjectAggregation/subscribeRecalculateAggregation', {
      projectId,
    }, IS_STORE_ROOT);
    dispatch('Comments/subscribeCommentsAmount', undefined, IS_STORE_ROOT);
    dispatch('Comments/subscribeCommentsAmount', 'subscribeCommentsAmount', IS_STORE_ROOT);
  },
  unsubscribeRowRelated({ rootState, commit }) {
    const { subscriptions } = rootState;
    if (subscriptions.length) {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    commit('clearTableSubscriptions', null, IS_STORE_ROOT);
  },
  setSelectedCells({ commit }, payload) {
    commit('setSelectedCells', payload);
    commit('UndoActions/clearLastUndoAction', null, IS_STORE_ROOT);
  },
  setEditCellEditMode({ commit }, payload) {
    commit('setEditCellEditMode', payload);
  },
  setDisableKeyboardController({ commit }, payload) {
    commit('setDisableKeyboardController', payload);
  },
  async repairLogo({ commit, state, dispatch, rootGetters }) {
    let key = rootGetters['Workspace/getActiveWorkspaceLogoKey'];
    let removeAction = 'ManageFiles/removeFilesFromCache';
    await dispatch(removeAction, {
      keys: [key],
    }, IS_STORE_ROOT);
    const isPersonalWorkspace = rootGetters['Workspace/isPersonalWorkspace'];
    if (isPersonalWorkspace) {
      key = state.creatorLogoKey;
      let parseAction = 'ManageFiles/parseFile';
      await dispatch(parseAction, {
        key,
        config: {
          level: 'public',
        },
      }, IS_STORE_ROOT);
      const url = rootGetters['ManageFiles/getFileByKey'](key);
      commit('setCreatorLogo', url);
      return;
    }
    let getAction = 'Workspace/getActiveWorkspaceLogo';
    dispatch(getAction, '', IS_STORE_ROOT);
  },
  async renameProjectColumnAction({ commit, dispatch, state }, { columnName, newColumnName, projectId, sharedLink = false }) {
    try {
      const scheduleId = state.scheduleId;
      const {
        data = {
        },
      } = await ProjectColumn.renameProjectCustomColumn({
        columnName: columnName,
        newColumnName,
        projectId,
        scheduleId,
      });
      const {
        response = {
        },
      } = data ?? {
      };

      const { schema = [], custom_fields = [] } = response;
      commit('setSchema', schema);
      commit('setCustomFields', custom_fields);

      await dispatch('ScheduleViews/getViews', {
        projectId,
        sharedLink,
      }, IS_STORE_ROOT);
    } catch (err) {
      console.log('renameProjectColumnAction err', err);
    }
  },
};
