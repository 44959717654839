export const getDefaultState = () => {
  return {
    activeCellHeader: '',
    availReferenceFields: [],
    cellPointer: null,
    copiedContent: null,
    creatorLogo: null,
    creatorLogoKey: null,
    customFields: [],
    customViewRows: null,
    defaultFields: [],
    hoveredColumn: {
    },
    isActiveEditMode: false,
    isDisableKeyboardController: false,
    libraryId: null,
    modalMode: '',
    modalRowId: '',
    modalType: 'add',
    projectPermissions: [],
    projectTags: [],
    readOnlyFields: [],
    rightAlignment: [
      'Budget Price',
      'Total Cost',
      'Budget Price - Project Override',
      'Library Budget Price',
    ],
    scheduleId: null,
    scheduleSessionUuid: null,
    schema: [],
    selectedCells: [],
    showCommentsModal: false,
  };
};
export const state = getDefaultState();
