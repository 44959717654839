import {
  parseGetRows, setFilteredCustomRows,
} from '@/utils/manageGetRows';
import SortRowsFactory from '@/utils/sorting/SortRowsFactory';
import productHeaders from '@/constants/productHeaders';
export const getters = {
  selectedViewTag: state => state.selectedViewTag,
  listDeletedRowsForRendering(state) {
    let { listDeletedRows } = state;
    const listOfRows = listDeletedRows.map(row => ({
      id: row.fields.find(item => item.name === 'id')?.value,
      type: row.fields.find(item => item.name === productHeaders.TYPE_DESIGNATION)?.value,
    }));
    return listOfRows;
  },
  viewInfoLength(state) {
    return state.viewInfo.length;
  },
  mappedDataRows(state, getters) {
    const { sortedViewInfo } = getters;
    return sortedViewInfo.map((row) => {
      let type = row[productHeaders.TYPE_DESIGNATION] ?? '';
      type = type?.toString().trim();
      type = (!type || type === '\b') ? '---' : type;
      return {
        type,
        id: row.id,
        model: row[productHeaders.MODEL],
        manufacturer: row[productHeaders.MANUFACTURER],
        productCode: row[productHeaders.PRODUCT_CODE],
      };
    });
  },
  currentRowItem(state, getters, rootState) {
    const { viewInfo: data } = state;
    const { modalRowId: id } = rootState.ProjectDetailsTableSchedule;
    return data.find(row => row.id === id);
  },
  arrTypeDesignation(state) {
    return state.viewInfo.map(e => ({
      name: e[productHeaders.TYPE_DESIGNATION],
      id: e.id,
    })).filter(e => e?.name);
  },
  sortedViewInfo(state, getters, rootState, rootGetters) {
    const sortingData = rootGetters['ScheduleViews/sortingData'];
    return getters.getSortedViewInfo(sortingData);
  },
  getSortedViewInfo: (state, getters) => (sortingData) => {
    const sortedData = new SortRowsFactory({
      sortOrder: sortingData?.sortOrder,
      sortBy: sortingData?.sortBy,
      items: state.viewInfo,
    }).sort();
    return getters.getFilteredViewInfo(sortedData);
  },
  getFilteredViewInfo: (state, getters, rootState, rootGetters) => (rows) => {
    const { activeTags } = rootState['ScheduleViews'];
    const selectedTag = rootGetters['ScheduleViews/selectedViewTag'];
    let filterTags = [];
    if (Object.keys(activeTags).length > 0) {
      filterTags = activeTags;
    } else if (selectedTag) {
      // for back support, if array and has length then use Tag as key
      // or object then use as it is
      // if string then use Tag as key and array with string as value
      if (Array.isArray(selectedTag) && selectedTag.length) {
        filterTags = {
          [productHeaders.TAG]: selectedTag,
        };
      } else if (Object.keys(selectedTag).length) {
        filterTags = selectedTag;
      } else if (typeof selectedTag === 'string') {
        filterTags = {
          [productHeaders.TAG]: [selectedTag],
        };
      }
    }

    if (Object.keys(filterTags).length == 0) {
      return rows;
    }
    return setFilteredCustomRows({
      arr: rows,
      filterTags,
      checkCompareRow: true,
    });
  },
  viewInfoWithDeletedComparedRows(state, getters, rootState, rootGetters) {
    const { sortedViewInfo: data } = getters;
    const rows = rootGetters['ScheduleCompare/deletedComparedRows'];
    if (!rows.length) return data;
    return rows.reduce((result, { fields = [], rowId: id }) => {
      if (!id) return result;
      const isRowExist = data.some(({ id: i } = {
      }) => i === id);
      if (isRowExist) return result;
      const obj = {
        ...parseGetRows(fields),
        isCompared: true,
      };
      return [...result, obj];
    }, [...data]);
  },
  viewInfoWithModifiedRow(state, getters, rootState) {
    const { viewInfoWithDeletedComparedRows: data = [] } = getters;
    const { modifiedRow: row } = rootState.ScheduleCompare;
    if (!row) return data;
    const { rowId = null, fields = [] } = row;
    const index = data.findIndex(({ id = null } = {
    }) => id === rowId);
    if (index === -1) return data;
    const obj = {
      ...parseGetRows(fields),
      isCompared: true,
    };
    return data.reduce((result, option, idx) => {
      if (idx === index) return [...result, option, obj];
      return [...result, option];
    }, []);
  },
  viewInfoToRendering(state, getters, rootState) {
    const { compareListWithVersions = [] } = rootState.ScheduleCompare;
    const { viewInfoWithModifiedRow: data = [] } = getters;
    return data.reduce((result, option) => {
      const {
        modified = [],
        status = '',
        rowId = '',
      } = compareListWithVersions.find(({ rowId } = {
      }) => rowId === option.id) ?? {
      };
      return [
        ...result,
        {
          ...option,
          compareData: {
            modified: Array.isArray(modified) ? modified : [],
            status,
            rowId,
          },
        },
      ];
    }, []);
  },
  loadedRowsTitle(state, getters, rootState, rootGetters) {
    const rowsCount = rootGetters['ProjectDetails/rowsCount'];
    const { viewInfoLength: infoLength } = getters;
    const GETTING_ROWS_TITLE = 'Getting rows';
    if (isNaN(rowsCount)) {
      return GETTING_ROWS_TITLE;
    }
    return `${GETTING_ROWS_TITLE} (${infoLength}/${rowsCount})`;
  },
  isMultipleRowsSelected(state) {
    return state.checkboxSelectedRows && !!state.checkboxSelectedRows.length;
  },
  finishedFetchingRows(state, getters, rootState, rootGetters) {
    const rowsCount = Number(rootGetters['ProjectDetails/rowsCount']);
    const currLength = Number(getters.viewInfoLength);
    if (isNaN(rowsCount) || isNaN(currLength)) {
      return true;
    }
    return currLength >= rowsCount;
  },
  scheduleTagsList: (state) => (col) => {
    const tagSet = new Set();
    const { viewInfo } = state;
    for (const info of viewInfo) {
      const tags = info[col];
      if (tags) {
        for (const t of tags) {
          tagSet.add(t);
        }
      }
    }
    return Array.from(tagSet);
  },
};
