import WorkspacesApi from '@/services/graphql/workspace';
import { IS_STORE_ROOT } from '@/constants';
import { Storage } from 'aws-amplify';
import { uploadWorkspaceLogoToS3 } from '@/utils';
import { backOff } from 'exponential-backoff';
import {
  NAME_CREATOR_ADMIN, TYPE_CREATOR_ADMIN, TYPE_MEMBER,
} from '@/constants/userPermissions';
import { subscribeForWorkspacePages } from '@/services/graphql/workspaceSubscriptions';
export const actions = {
  async getWorkspacesList({ commit, dispatch, state },
    {
      checkBackOff = false,
      limit = 100,
      nextToken,
    } = {
    }) {
    try {
      let data;
      if (checkBackOff) {
        commit('spinner', true, IS_STORE_ROOT);
        data = await backOff(async () => {
          const { data } = await WorkspacesApi.getWorkspacesList({
            limit,
            nextToken,
          });
          const { data: listOfWorkspaces } = data.response;
          if (!listOfWorkspaces.length) {
            throw new Error('Empty list of workspaces');
          }
          return data;
        }, {
          jitter: 'full',
          delayFirstAttempt: true,
          maxDelay: 30000,
          startingDelay: 1000,
        });
      } else {
        const workspacesData = await WorkspacesApi.getWorkspacesList({
          limit,
          nextToken,
        });
        data = workspacesData.data;
      }
      const { data: listOfWorkspaces, nextToken: resNextToken } = data.response || {
      };
      if (!listOfWorkspaces.length) {
        const errMessage = 'You have an empty workspace list.';
        dispatch('handleError', errMessage, IS_STORE_ROOT);
      }
      const list = nextToken ?
        [...state.workspacesList, ...listOfWorkspaces] : listOfWorkspaces;
      commit('setWorkspacesList', list);
      if (resNextToken) {
        await dispatch('getWorkspacesList', {
          nextToken: resNextToken,
        });
      }
    } catch (err) {
      console.log('err get workspaces list', err);
    }
  },
  async createWorkspacePage({ commit, state, dispatch, rootGetters }, { workspaceId }) {
    try {
      let result;
      if (rootGetters['FeatureFlags/isToggleToMoveToVerifiedArea']) {
        const { data } = await WorkspacesApi.createWorkspacePageV2({
          workspaceId,
        });
        result = data;
      } else {
        const { data } = await WorkspacesApi.createWorkspacePage({
          workspaceId,
        });
        result = data;
      }
      commit('setWorkspacePagesForSpecifiedWs', [...state.workspacePagesForSpecifiedWs, result.response]);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async deleteWorkspacePage({ commit, state, dispatch }, { workspaceId, pageId }) {
    try {
      await WorkspacesApi.deleteWorkspacePage({
        workspaceId,
        pageId,
      });
      commit('setWorkspacePagesForSpecifiedWs', state.workspacePagesForSpecifiedWs.filter(workspacePage => workspacePage.id !== pageId));
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  async listWorkspacePages({ commit, dispatch, state }, { workspaceId = null, loadAll = true, accessToken = null, pageId = null, nextToken: nextPageToken = null }) {
    commit('setIsFetching', {
      workspacePages: true,
    });
    try {
      const { data } = await WorkspacesApi.listWorkspacePages({
        ...workspaceId && {
          workspaceId,
        },
        pageId,
        accessToken,
        nextToken: nextPageToken,
      });
      const { nextToken, data: pages } = data.response;
      commit('setWorkspacePagesForSpecifiedWs', [...state.workspacePagesForSpecifiedWs, ...pages]);
      if (loadAll && nextToken) {
        dispatch('listWorkspacePages', {
          nextToken,
          workspaceId,
          loadAll,
        });
      }
    } catch (e) {
      console.error(e);
      dispatch('handleError', e, IS_STORE_ROOT);
    } finally {
      commit('setIsFetching', {
        workspacePages: false,
      });
    }
  },
  async getWorkspacesListForFollowing({ commit, dispatch, state }, { nextToken, limit = 20 } = {
  }) {
    try {
      const { data } = await WorkspacesApi.getWorkspacesListForFollowing({
        limit,
        nextToken,
      });
      const { data: list, nextToken: resNextToken } = data.response;
      const newList = nextToken ? [...state.workspacesListForFollowing, ...list] : list;
      commit('setWorkspacesListForFollowing', newList);
      if (resNextToken) {
        await dispatch('getWorkspacesListForFollowing', {
          nextToken: resNextToken,
        });
      }
    } catch (err) {
      console.log('err getWorkspacesListForFollowing', err);
    }
  },
  async prepareWorkspaces({ commit, dispatch, getters, state }, {
    params,
    matched,
    query,
    isAnonymous = false,
    checkBackOff = false,
  }) {
    if (!state.workspacesList.length && !isAnonymous) {
      await dispatch('getWorkspacesList', {
        checkBackOff,
      });
    }
    let activeWorkspaceId = state.activeWorkspaceId;
    if (!state.activeWorkspaceId) {
      activeWorkspaceId = getters.getFirstWorkspaceIdInList;
      const storageWorkspaceId = window.localStorage.getItem(getters.getWorkspaceStoragePrefix);
      if (storageWorkspaceId && getters.getWorkspaceById(storageWorkspaceId)) {
        activeWorkspaceId = storageWorkspaceId;
      }
    }
    if (matched.some(record => record.name == 'workspaces')) {
      const paramsWorkspaceId = params?.wId;
      const queryWorkspaceId = query?.workspaceId; // until back won't implement new links on their side
      if (paramsWorkspaceId && getters.getWorkspaceById(paramsWorkspaceId) || paramsWorkspaceId && isAnonymous) {
        activeWorkspaceId = paramsWorkspaceId;
      } else if (queryWorkspaceId && getters.getWorkspaceById(queryWorkspaceId)) {
        activeWorkspaceId = queryWorkspaceId;
        commit('setIsNeedRedirect', true);
      } else {
        commit('setIsNeedRedirect', true);
      }
    }
    window.localStorage.setItem(getters.getWorkspaceStoragePrefix, activeWorkspaceId);
    commit('setActiveWorkspaceId', activeWorkspaceId);
    dispatch('updateActiveWorkspaceData', activeWorkspaceId);
  },
  async needUserPremium({ commit }, payload) {
    try {
      const { data } = await WorkspacesApi.isUserNeedPremium(payload);
      commit('setNeedUserPremium', data.response);
    } catch (err) {
      console.log('isUserNeedPremium err', err);
    }
  },
  async createWorkspace({ dispatch }, payload) {
    try {
      if (payload.logo) {
        payload.logo = await uploadWorkspaceLogoToS3(payload.logo);
      }
      const createWorkspace = 'createWorkspaceV2';
      await WorkspacesApi[createWorkspace](payload);
      await dispatch('getWorkspacesList');
      await dispatch('refreshToken', null, IS_STORE_ROOT);
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async editWorkspace({ dispatch, state, commit, rootState }, {
    logo = '',
    workspaceId,
    description,
    name,
  }) {
    try {
      if (logo && logo instanceof File) {
        logo = await uploadWorkspaceLogoToS3(logo);
      }
      // we get from here another document so we don't need it
      const { data } = await WorkspacesApi.updateWorkspace({
        logo,
        workspaceId,
        description,
        name,
      });
      const dataToSet = {
        logo: data.response.logo,
        name: data.response.name,
        description: data.response.description,
      };
      commit('setWorkspacesList', state.workspacesList.map(w => {
        if (w.id == data.response.id) {
          return {
            ...w,
            ...dataToSet,
          };
        }
        return w;
      }));
      commit('setWorkspacePagesForSpecifiedWs', state.workspacePagesForSpecifiedWs.map(page => {
        if (page.id == workspaceId) {
          return {
            ...page,
            ...dataToSet,
          };
        }
        return page;
      }));
      const allWsPages = rootState.Collections.wsPages;
      commit('Collections/setWsPages', allWsPages.map(page => {
        if (page.id == workspaceId) {
          return {
            ...page,
            ...dataToSet,
          };
        }
        return page;
      }), IS_STORE_ROOT);
      dispatch('updateActiveWorkspaceLogo');
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async updateActiveWorkspaceLogo({ getters, dispatch }) {
    const { isPersonalWorkspace } = getters;
    if (!isPersonalWorkspace) {
      await dispatch('getActiveWorkspaceLogo');
    }
  },
  async listUsersInWorkspace({ commit, state, rootGetters, dispatch }, {
    type,
    useSpinner = true,
    loadAll = true,
    nextTokenForRequest = null,
    firstRequest = true,
    updateUsedLicenses = false,
  }) {
    const mutationName = rootGetters['FeatureFlags/useSkeleton'] ? 'setIsLoadingRowData' : 'spinner';
    const usersMutations = 'set'.concat(type[0].toUpperCase() + type.slice(1), 'Users');
    if (firstRequest) commit(usersMutations, []);
    try {
      const { activeWorkspaceId: workspaceId } = state;
      if (useSpinner) {
        commit(mutationName, true, IS_STORE_ROOT);
      }
      const { data } = await WorkspacesApi.listUsersInWorkspace({
        membership: type,
        workspaceId,
        nextToken: nextTokenForRequest,
      });
      const { data: users, nextToken } = data.response;
      commit(usersMutations, [...state[`${type}Users`], ...users]);
      if (nextToken && loadAll) {
        dispatch('listUsersInWorkspace', {
          type,
          nextTokenForRequest: nextToken,
          useSpinner,
          firstRequest: false,
          loadAll,
        });
      } else {
        if (updateUsedLicenses && (type === TYPE_MEMBER)) {
          const currentUsers = state[`${type}Users`];
          dispatch('updateUsedLicenses', currentUsers?.length || 0);
        }
      }
    } catch (err) {
      console.log('err get listMemberUsersInWorkSpace', err);
    } finally {
      if (useSpinner) {
        commit(mutationName, false, IS_STORE_ROOT);
      }
    }
  },
  listAllUsersInWorkspace({ dispatch, state }, updateUsedLicenses) {
    state.workspaceUserTypes.forEach(type => {
      dispatch('listUsersInWorkspace', {
        type,
        updateUsedLicenses,
      });
    });
  },
  async updateWorkspaceMemberships({ commit, dispatch, state }, payload) {
    if (Array.isArray(payload)) {
      await Promise.all(payload.map(p => dispatch('updateWorkspaceMembership', {
        permissionType: p.type,
        workspaceId: state.activeWorkspaceId,
        sharingUserId: p.id,
        loadList: false,
      }))).then(() => {
        commit('spinner', false, {
          root: true,
        });
      });
    }
  },
  async updateWorkspaceMembership({ commit, state, dispatch }, {
    permissionType,
    workspaceId,
    sharingUserId,
    loadList = true,
  }) {
    try {
      const { data } = await WorkspacesApi.updateWorkspaceMembership({
        permissionType: permissionType === 'creator(admin)' ? 'workspaceCreatorAdmin' : permissionType,
        workspaceId,
        sharingUserId,
      });
      // eslint-disable-next-line no-unused-vars
      const { userInfo, ...updatedWorkspace } = data.response;
      const updatedWorkspacesList = state.workspacesList.map(w => w.id == updatedWorkspace.id ? updatedWorkspace : w);
      commit('setWorkspacesList', updatedWorkspacesList);
      if (loadList) {
        dispatch('listAllUsersInWorkspace');
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async deleteWorkspaceMemberships({ dispatch, state }, { member, type }) {
    if (Array.isArray(member)) {
      await Promise.all(member.map(p => dispatch('deleteWorkspaceMembership', {
        member: {
          workspaceId: state.activeWorkspaceId,
          userId: p.id,
        },
        loadList: false,
      })));
      dispatch('listUsersInWorkspace', {
        type,
      });
    }
  },
  updateUsedLicenses({ state, commit }, usedLicenses) {
    if (!usedLicenses) return;
    const { workspacesList, activeWorkspaceId } = state;
    const newWsList = workspacesList.map(e => ({
      ...e,
      ...((e.id === activeWorkspaceId) && {
        usedLicenses,
      }),
    }));
    commit('setWorkspacesList', newWsList);
  },
  async deleteWorkspaceMembership({ dispatch }, { type, member }) {
    try {
      const { data } = await WorkspacesApi.deleteWorkspaceMembership(member);
      const { usedLicenses = 0 } = data.response || {
      };
      dispatch('updateUsedLicenses', usedLicenses - 1);
      if (type) {
        dispatch('listUsersInWorkspace', {
          type,
        });
      }
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  async manageInvitedUsersForShareWorkspace({ commit, state, getters }, {
    criteria,
    members,
    variables,
  } = {
  }) {
    const { isPersonalWorkspace } = getters;
    const { invitedUsersForShareWorkspace } = state;
    if (['inviteUsers', 'createPermission', 'updatePermission'].includes(criteria) && !isPersonalWorkspace) {
      const email = variables.email;
      const userId = members.find(user => user.email === email)?.id;
      if (userId) {
        if (!getters.isUserMemberInWorkspace(userId)) {
          commit('setInvitedUsersForShareWorkspace', [
            ...invitedUsersForShareWorkspace,
            {
              email,
              id: userId,
            },
          ]);
        }
      }
    }
  },
  async inviteToWorkspace({ commit, state, dispatch }, { members, emailMessage }) {
    try {
      const filteredMembers = members.map(e => ({
        ...e,
        role: e.role === NAME_CREATOR_ADMIN.toLowerCase() ? TYPE_CREATOR_ADMIN : e.role,
      }));
      const { data } = await WorkspacesApi.inviteToWorkspace({
        workspaceId: state.activeWorkspaceId,
        members: filteredMembers,
        emailMessage,
      });
      const requestId = data.response;
      await WorkspacesApi.invitationReport({
        requestId,
      }).subscribe({
        next: ({ value }) => {
          const { error, message } = value.data.response;
          if (error) {
            dispatch('handleError', message, IS_STORE_ROOT);
            return;
          }
          commit('openSnackBar', {
            title: message,
          }, IS_STORE_ROOT);
          dispatch('listAllUsersInWorkspace', true);
        },
      });
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    }
  },
  updateCountPremiumLicensesCurrentWs({ state, commit }, premiumLicenses) {
    const { workspacesList, activeWorkspaceId } = state;
    const newWsList = workspacesList.map(e => ({
      ...e,
      ...(e.id === activeWorkspaceId && {
        premiumLicenses,
      }),
    }));
    commit('setWorkspacesList', newWsList);
  },
  async getActiveWorkspaceLogo({ commit, getters, dispatch, rootGetters }) {
    try {
      const { getActiveWorkspaceLogoKey: key } = getters;
      if (!key) {
        commit('setActiveWorkspaceLogoUrl', null);
      } else {
        let url = null;
        if (rootGetters['FeatureFlags/useIncreaseCaching']) {
          await dispatch('ManageFiles/parseFile', {
            key,
            config: {
              level: 'public',
            },
          },
          IS_STORE_ROOT
          );
          url = rootGetters['ManageFiles/getFileByKey'](key);
        } else {
          url = await Storage.get(key,
            {
              level: 'public',
            });
        }
        commit('setActiveWorkspaceLogoUrl', url);
      }
    } catch (err) {
      console.log('getActiveWorkspaceLogo', err);
    }
  },
  /**
   * @param dispatch
   * @param { String } id - pageId
   * @param { String } workspaceId
   * @desc params workspaceId and pageId are equal
   * because we use only one ws page for ws.
   * You can pass one of them or none if you want subscribe
   * on all pages
   */
  async onWorkspacePageChange({ dispatch }, { id = '', workspaceId = '' } = {
  }) {
    try {
      const haveVariables = id || workspaceId;
      const variables = {
        id,
        workspaceId,
      };
      const subscription = await subscribeForWorkspacePages(haveVariables ? variables : {
      }, ({ data }) => {
        const { response } = data;
        // we work with specified ws pages
        if (haveVariables) {
          dispatch('onSpecifiedWorkspacePageChange', response);
          return;
        }
        // we work with all ws pages
        dispatch('onAllWorkspacePageChange', response);
      });
      dispatch('ManageSubscriptions/setSubscription', {
        subscription,
        title: 'workspace_page_subscription'.concat(id || ''),
      }, IS_STORE_ROOT);
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
    }
  },
  onSpecifiedWorkspacePageChange({ commit, state }, changedPage) {
    const { id, status } = changedPage;
    if (status == 'deleted') {
      commit('setWorkspacePagesForSpecifiedWs', state.workspacePagesForSpecifiedWs.filter(page => page.id !== id));
      return;
    }
    commit('setWorkspacePagesForSpecifiedWs', [...state.workspacePagesForSpecifiedWs, changedPage]);
  },
  onAllWorkspacePageChange({ commit, rootState }, changedPage) {
    const { id, status } = changedPage;
    if (status == 'deleted') {
      commit('Collections/setWsPages', rootState.Collections.wsPages.filter(page => page.id !== id), IS_STORE_ROOT);
      return;
    }
    commit('Collections/setWsPages', [...rootState.Collections.wsPages, changedPage], IS_STORE_ROOT);
  },
  async updateActiveWorkspaceData({ commit }, activeWorkspaceId) {
    try {
      const { data } = await WorkspacesApi.getWorkspace({
        workspaceId: activeWorkspaceId,
      });
      commit('setActiveWorkspaceType', data?.response?.type);
      const published = data?.response?.published;
      if (published !== undefined) {
        commit('setWorkspacePagePublished', published);
      } else {
        console.error('Error getting workspace data for', activeWorkspaceId);
      }
    } catch (err) {
      console.error('Error getting workspace data for', activeWorkspaceId);
    }
  },
};
