import { API } from 'aws-amplify';
import {
  VIEW_PARAMS,
  STRIPE_SUBSCRIPTION,
} from '@/services/graphql/fragments';
import requestBuilder from '@/services/graphql/requestBuilder';
import { GRAPHQL_TYPES } from '@/constants/cores';
const PRICE = `
    active
    billing_scheme
    currency
    id
    metadata
    nickname
    product
    recurring {
      interval
      interval_count
    }
    unit_amount
    unit_amount_decimal
`;
export default {
  async getListPrice(variables) {
    const res = await new requestBuilder({
      requestName: 'getListPrice',
      requestVariables: {
        workspaceLicense: 'Boolean',
        currency: 'String',
        filter: 'priceForProduct',
      },
      response: PRICE,
      variables,
    }).processRequest();

    return res.data.response.map(price => ({
      ...price,
      metadata: JSON.parse(price.metadata),
    }));
  },
  saveAndAttachPaymentMethodId(variables) {
    const query = `
      mutation saveAndAttachPaymentMethodId (
        $paymentMethodId: String!
        $currency: String
      ) {
        response: saveAndAttachPaymentMethodId (
          currency: $currency
          paymentMethodId: $paymentMethodId
        ) {
          success
          message
          paymentMethodId
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  stripeCreateSubscribe(variables) {
    const query = `
      mutation stripeCreateSubscribe (
        $priceId: String!,
        $currency: String
      ) {
        response: stripeCreateSubscribe (
          priceId: $priceId,
          currency: $currency
        ) {
          stripeSubscriptionId
        }
      }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getUserSubscriptions() {
    const query = `
    query getUserSubscriptions {
     getUserSubsriptions {
       ${STRIPE_SUBSCRIPTION}
     }
    }`;
    return API.graphql({
      query,
    });
  },
  changeSubscriptionPlan(variables) {
    const query = `
    mutation changeSubscriptionPlan (
        $subId: String!
        $priceId: String!
      ) {
        changeSubscriptionPlan(
          subId: $subId
          priceId: $priceId
        ) {
          sub_id
      }
    }
    `;
    return API.graphql({
      query,
      variables,
    });
  },
  getUserInvoices() {
    const query = `
      query getUserInvoices {
        response: getUserInvoices {
          keyToPdf
          inId
          data {
            description
            start
            end
          }
        }
      }
    `;
    return API.graphql({
      query,
    });
  },
  getViewParams(variables) {
    return new requestBuilder({
      requestName: 'getViewParams',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        viewId: 'String',
      },
      response: VIEW_PARAMS,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
  setViewParams(variables) {
    return new requestBuilder({
      requestName: 'setViewParams',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        username: 'String',
        params: '[FieldInput]',
      },
      response: VIEW_PARAMS,
      authMode: 'AWS_IAM',
      variables,
    }).processRequest();
  },
  deleteViewParams(variables) {
    return new requestBuilder({
      requestName: 'deleteViewParams',
      requestType: GRAPHQL_TYPES.MUTATION,
      requestVariables: {
        viewId: 'String',
      },
      response: VIEW_PARAMS,
      variables,
      authMode: 'AWS_IAM',
    }).processRequest();
  },
};
