import {
  TYPE_GUEST,
  TYPE_MEMBER,
} from '@/constants/userPermissions';
export const getDefaultState = () => ({
  workspacesList: [],
  workspacesListForFollowing: [],
  activeWorkspaceId: null,
  activeWorkspacePrefix: 'active_workspace_',
  invitedUsersForShareWorkspace: [],
  memberUsers: [],
  guestUsers: [],
  workspacePagesForSpecifiedWs: [],
  workspaceUserTypes: [TYPE_MEMBER, TYPE_GUEST],
  userPremium: false,
  needRedirect: false,
  activeWorkspaceLogoUrl: null,
  workspacePagePublished: false,
  isFetching: {
    workspacePages: false,
  },
});
export const state = getDefaultState();
