export const mutations = {
  setActiveTags(state, payload) {
    let payloadCopy = {
      ...payload,
    };
    if (Object.keys(payloadCopy).length) {
      Object.keys(payloadCopy).forEach(key => {
        if (Array.isArray(payloadCopy[key]) && payloadCopy[key].length) {
          payload[key] = payloadCopy[key];
        } else {
          delete payload[key];
        }
      });
    }
    state.activeTags = payload;
  },
  // Avoid using this outside of the 'setSelectedViewId' action.
  // We need to sync the tags with it.
  setSelectedViewId(state, payload) {
    state.selectedViewId = payload;
  },
  setSelectedRowHeight(state, payload) {
    state.selectedRowHeight = payload;
  },
  setViews(state, payload) {
    state.views = payload;
  },
};
